import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import styled from 'styled-components'
import { ButtonLink } from './buttonLink'
import { ContentSlide } from './contentSlide/contentSlide'
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface AboutEricProps { }

const ContentContainer = styled.div`
  text-align: left;
`
const ImageContainer = styled.div`
  text-align: left;
`
export const AboutEric: React.FunctionComponent<AboutEricProps> = () => {
  return (
    <ContentSlide
      slideRight
      rightSize={4}
      left={
        <ContentContainer>
          <h2>
            Over 15 Years of Experience
          </h2>
          <p>
            Eric Wooley has been working as a freelance contractor, at an agency, or for startups for <strong>over 15 years</strong>.
          </p>
          <h3>Fullstack and Capable</h3>
          <p>
            From Wordpress to kubernetes, we have experience with tons of technologies, and strive to keep ourselves up to date with all the latest tech.
          </p>
          <h3>How we can help</h3>
          <ul style={{ fontSize: 16, marginLeft: 14, marginTop: 14 }}>
            <li>Sql and database queries</li>
            <li>React &amp; React Native</li>
            <li>Nodejs, with express, or apollo graphql</li>
            <li>Hasura</li>
            <li>NextJS</li>
            <li>Tech stack setup and development</li>
            <li>Consulting on teambuilding or interviewing the right people for your team</li>
          </ul>
        </ContentContainer>
      }
      right={
        <ImageContainer>
          <StaticImage
            style={{ borderRadius: '70% 0% 70% 0%' }}
            placeholder="blurred"
            src="../img/profile.jpg"
            alt="video call"
            width={1374}
            height={1373}
          />
        </ImageContainer>
      }
    />
  )
}

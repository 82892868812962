import React from 'react'
import { PageProps } from 'gatsby'

import { PageLayout } from '@/components/pageLayout'
import { SwirlIntroAbout } from '@/components/swirlIntroAbout/swirlIntroAbout'
import Grid from '@material-ui/core/Grid'
import { Container } from '@/components/container'
import { NewsLetter } from '@/components/newsLetter'
import styled from 'styled-components'
import { AboutEric } from '@/components/aboutEric'
import { GetInTouch } from '@/components/getInTouch'

const SectionWrapper = styled.div`
  padding: 64px 0;
`
const IframeWrapper = styled.div`
  &>iframe {
    min-height: 100vh;
    width: 90%;
    margin: 5px 0;
  }
`

const Home: React.FC<PageProps> = ({ path }) => (
  <PageLayout pathname={path}>
    <SwirlIntroAbout />
    <Container>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <NewsLetter />
        </Grid>
        <Grid item lg={12}>
          <SectionWrapper>
            <AboutEric />
          </SectionWrapper>
        </Grid>
        <Grid item lg={12}>
          <SectionWrapper>
            <GetInTouch />
          </SectionWrapper>
          <div style={{ textAlign: 'center' }}>
            <h2>Schedule a Meeting</h2>
            <hr />
            <IframeWrapper className="calendly-inline-widget" data-url="https://calendly.com/ericwooley/meetwith-eric?hide_gdpr_banner=1" style={{ "minWidth": 320, "minHeight": '100vh' }}>

              {/* <iframe src="http://www.example.com"/> */}
            </IframeWrapper>
            <script type="text/javascript" src="https://assets.calendly.com/assets/external/widget.js" async></script>
            <hr />
          </div>
        </Grid>
        {/* <Grid item lg={12}>
          <SectionWrapper>
            <HowCanTengableHelp />
          </SectionWrapper>
        </Grid>
        <Grid item lg={12}>
          <SectionWrapper>
            <Features />
          </SectionWrapper>
        </Grid>
        <Grid item lg={12}>
          <SectionWrapper>
            <WhyTengable />
          </SectionWrapper>
        </Grid>
        <Grid item lg={12}>
          <SectionWrapper>
            <h2 style={{ textAlign: `center` }}>
              Make Sure to subscribe to the newsletter for updates on new
              features .
            </h2>
            <NewsLetter />
          </SectionWrapper>
        </Grid> */}
      </Grid>
    </Container>
  </PageLayout>
)

export default Home

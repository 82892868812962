import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import styled from 'styled-components'
import { ContentSlide } from './contentSlide/contentSlide'
import { DemoButton } from './demoLink'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface GetInTouchProps { }

const DemoContainer = styled.div`
  text-align: right;
`
const ImageContainer = styled.div`
  text-align: center;
  width: 100%;
`
export const GetInTouch: React.FunctionComponent<GetInTouchProps> = () => {
  return (
    <ContentSlide
      slideLeft
      rightSize={6}
      left={
        <ImageContainer>
          <StaticImage
            placeholder="blurred"
            src="../img/undraw/undraw_to_the_moon_v1mv.svg"
            alt="video call"
            width={926}
            height={749}
          />
        </ImageContainer>
      }
      right={
        <DemoContainer className="demo-container">
          <h2>Get In Touch</h2>
          <p>Visit our <Link to="/contact">contact page</Link> to get in touch, or schedule a meeting below.</p>
        </DemoContainer>
      }
    />
  )
}
